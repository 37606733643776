/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listLogisticsCompany = params => axios({
    url: '/api/order/logistics/logisticsCompany/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addLogisticsCompany = params => axios({
    url: '/api/order/logistics/logisticsCompany/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editLogisticsCompany = params => axios({
    url: '/api/order/logistics/logisticsCompany/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delLogisticsCompany = params => axios({
    url:'/api/order/logistics/logisticsCompany/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdLogisticsCompany = params => axios({
    url: '/api/order/logistics/logisticsCompany/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
