<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
          <a-form-model-item label="LOGO">
            <QiniuUpload type="image" :value.sync="rowData.logo"></QiniuUpload>
          </a-form-model-item>
          <a-form-model-item label="公司名称" prop="name">
                <a-input v-model="rowData.name" placeholder="公司名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="公司编码" prop="code">
                <a-input v-model="rowData.code" placeholder="公司编码"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否启用" prop="flagEnable">
              <a-switch
                    :disabled="handle == 'check'"
                    v-model="rowData.flagEnable"
                    checked-children="启用"
                    un-checked-children="禁用"
                    default-checked/>
            </a-form-model-item>
            <a-form-model-item label="在职状态" prop="queryType">
              <a-radio-group v-model="rowData.queryType">
                <a-radio :value="1">自动查询</a-radio>
                <a-radio :value="2">手动查询</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="(remark">
                <a-input v-model="rowData.remark" placeholder="备注"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editLogisticsCompany, selectByIdLogisticsCompany, addLogisticsCompany } from '../api/LogisticsCompanyApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '公司名称', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '公司编码', trigger: 'blur' }
                ],
                flagEnable: [
                    { required: true, message: '是否启用', trigger: 'blur' }
                ],
                flagAutoQuery: [
                    { required: true, message: '自动查询物流信息', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '备注', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
            if(handle == 'add'){
              this.$set(this.rowData, 'flagEnable', true)
              this.$set(this.rowData, 'queryType', 1)
            }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addLogisticsCompany(this.rowData) : await editLogisticsCompany(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
